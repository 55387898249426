import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import RequestsApi from './RequestsApi';
import RequestsActions from './Components/RequestsActions';
import { WhiteSpace } from '@dex/bubl-dash';
import { Status } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'requests',
    zone: 'requests',
    path: '/requests',
    title: "Merchant Requests",
    endpoint: RequestsApi.endpoint,
    modelName: 'Requests',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewRequests"],
    viewCaps: ["viewRequests"],
    createCaps: ["createRequests"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Review",
                path: settings.path + "/status/review",
            },
            {
                label: "Processing",
                path: settings.path + "/status/processing",
            },
            {
                label: "Shipping",
                path: settings.path + "/status/shipping",
            },
            {
                label: "Delivered",
                path: settings.path + "/status/delivered",
            },
            {
                label: "Returned",
                path: settings.path + "/status/returned",
            },
            {
                label: "Denied",
                path: settings.path + "/status/denied",
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Import Multiple",
                path: settings.path + "/import",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: RequestsApi.get,
    indexFetchParams: (routeName, params, user, app) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        filters.include = [
            {
                relation: "shippingProvider",
                scope: {
                    fields: ["id", "name", "modelName"]
                }
            },
            {
                relation: "product",
                scope: {
                    fields: ["id", "name", "modelName"]
                }
            },
            {
                relation: "merchant",
                scope: {
                    fields: ["id", "companyName", "modelName", "mid"]
                }
            },
            {
                relation: "vendor",
                scope: {
                    fields: ["id", "companyName", "modelName"]
                }
            },
        ];

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "requests", label: "Merchant Requests" }
        ];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'mid'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Request ID",
                key: "id",
                type: "text",
                sort: 'id'
            },
            {
                label: "Merchant",
                key: "merchantId",
                type: "relationship",
                model: "Merchants"
            },
            {
                label: "Date Requested",
                key: "created.date",
                type: "date",
            },
            {
                label: "Date Shipped",
                key: "shippedOn",
                type: "date",
            },
            {
                label: "Date Delivered",
                key: "deliveredOn",
                type: "date",
            },
            {
                label: "Product",
                key: "productid",
                type: "relationship",
                model: "Products"
            },
            {
                label: "Shipping Provider",
                key: "shippingProviderId",
                type: "relationship",
                model: "ShippingProviders"
            },
            {
                label: "Vendors",
                key: "vendorId",
                type: "relationship",
                model: "Vendors"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Request ID",
                key: "this",
                format: "ModelLink",
                sort: 'id'
            },
            {
                label: "Status",
                key: "status",
                format: "status",
            },
            {
                label: "Merchant Name",
                key: "merchant",
                format: "ModelLink",
            },
            {
                label: "MID",
                key: "merchant.mid",
            },
            {
                label: "Box Size",
                key: "productMeta.boxSize",
                format: "titleCase",
            },
            {
                label: "No of Rolls",
                key: "productMeta.numberOfRolls",
                format: "number",
            },
            {
                label: "Batch",
                key: "batch",
                format: "titleCase",
            },
            {
                label: "Shipping Provider",
                key: "shippingProvider",
                format: "ModelLink",
            },
            {
                label: "Vendor",
                key: "vendor",
                format: "ModelLink",
            },
            {
                label: "Date Requested",
                key: "created.date",
                format: "dateDay",
                sort: "created.date",
            },
            {
                label: "Date Shipped",
                key: "shippedOn",
                format: "dateDay",
                sort: "shippedOn",
            },
            {
                label: "Date Delivered",
                key: "deliveredOn",
                format: "dateDay",
                sort: "deliveredOn",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <RequestsActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: RequestsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: ['merchant', 'shippingProvider', 'product', 'instruction', 'vendor'] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace />{data && <Status status={data?.status} prefix='request.status' />}</>;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};